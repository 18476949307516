:root {
	--marquee-width: 100vw;
	--marquee-height: 80px;
	/* --marquee-elements: 12; */ /* defined with JavaScript */
	--marquee-elements-displayed: 2;
	--marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
	--marquee-animation-duration: calc(var(--marquee-elements) * 3s);
}

@screen sm {
	:root {
		--marquee-elements-displayed: 2;
	}
}

@screen md {
	:root {
		--marquee-elements-displayed: 3;
	}
}

@screen lg {
	:root {
		--marquee-elements-displayed: 4;
	}
}

@screen xl {
	:root {
		--marquee-elements-displayed: 5;
	}
}

@screen 3xl {
	:root {
		--marquee-elements-displayed: 6;
	}
}

.logo-slider {
	.logo-container {
		@apply overflow-hidden;
		@apply relative;
		width: var(--marquee-width);
		height: var(--marquee-height);

		&::before,
		&::after {
			background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
			content: "";
			// height: 100px;
			@apply absolute;
			@apply h-20;
			@apply w-12.5;
			@apply md:w-50;
			@apply z-2;
			@apply top-0;
		}

		&::after {
			@apply right-0;
			transform: rotateZ(180deg);
		}

		&::before {
			@apply left-0;
		}
	}

	.logos {
		@apply flex;
		@apply h-full;
		animation: scrolling var(--marquee-animation-duration) linear infinite;
	}

	.logo {
		width: var(--marquee-element-width);
		@apply whitespace-nowrap;
		@apply flex;
		@apply justify-center;
		@apply items-center;

		img {
			@apply h-full;
			@apply w-auto;
			@apply object-contain;
		}
	}
}

@keyframes scrolling {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements))); }
}



