.error-page {
	@apply min-h-screen;

	.error-box {
		@apply -mt-60;

		@screen lg {
			@apply m-0;
		}
	}
}
