.free-content-slider {
	.image-container {
		picture {
			transition: transform 0.3s ease;
		}

		&:hover {			
			picture {
				transform: scale(1.05);
			}
		}
	}
}

