.contact {
	.headline {
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: theme("colors.black");
	}

	.image-container {
		.image-overlay {
			transition: opacity 0.3s ease;
		}

		&:hover {
			.image-overlay {
				@apply opacity-100;
			}
		}
	}

	.swiper-slide {
		@screen lg {
			width: auto #{ !important };
		}
	}
}
