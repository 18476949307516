.header-menu {
	@apply z-40;
	@apply top-0;
	@apply bottom-0;
	@apply bg-transparent;
	@apply h-header;
	// overflow-y: scroll;
	// -webkit-overflow-scrolling: touch;
	@apply md:overflow-hidden;

	&.open-menu {
		@apply h-screen;
		@apply w-screen;
		@apply z-50;
		background-color: #00304a !important;
		transition: background-color 0.3s ease;

		.nav {
			@apply h-auto;
		}

		.menu-nav {
			@apply hidden;
		}

		.contact-button-dark {
			@apply hidden;
		}

		.contact-button-light {
			@apply hidden;
		}

		.logo {
			svg path {
				fill: #fff !important;
			}
		}

		.lang-switcher {
			@screen md {
				@apply hidden;
			}
		}
	}

	.menu-item {
		padding: 0.5rem 0;
		@apply relative;
		transition: color 0.3s ease;

		a {
			@apply p-0;
			@apply inline-flex;
			@apply flex-col;

			@screen md {
				&:hover {
					@apply bg-transparent;

					&::after {
						@apply w-full;
					}
				}

				&::after {
					content: "";
					@apply inline;
					@apply w-0;
					@apply bg-white;
					@apply h-px;
					transition: all 0.3s ease;
				}
			}
		}

		&.active-tab {
			@screen md {
				a {
					&::after {
						@apply w-full;
					}
				}
			}
		}
	}

	.menu-icon {
		@apply cursor-pointer;
		@apply select-none;
		@apply px-2.5;
		@apply py-5;
		@apply bg-transparent;
		@apply border-0;
		font-size: 0;

		&:focus {
			@apply outline-none;
		}

		.navicon {
			@apply bg-white;
			@apply block;
			@apply h-0.5;
			@apply relative;
			@apply w-5;

			&::before,
			&::after {
				@apply bg-white;
				content: "";
				@apply block;
				@apply h-full;
				@apply absolute;
				@apply w-full;
			}

			&::before {
				@apply top-2;
			}

			&::after {
				@apply -top-2;
			}
		}

		&.close {
			.navicon {
				@apply bg-transparent;

				&::before {
					@apply transform;
					@apply -rotate-45;
					@apply bg-white;
				}

				&::after {
					@apply transform;
					@apply rotate-45;
					@apply bg-white;
				}
			}

			&:not(.steps) .navicon::before,
			&:not(.steps) .navicon::after {
				@apply top-0;
			}
		}
	}

	#menu {
		@apply bg-transparent;

		&.open {
			@apply block;
			@apply pt-20;
			@apply flex-col;
			@apply w-full;
			@apply h-auto;
			@apply z-20;

			.menu-items {
				@apply flex-col;
				@apply h-auto;
			}
		}
	}

	&.header-shadow {
		box-shadow: 0 1px 5px 3px rgb(15 34 84 / 5%);
	}

	&.white-header {
		@apply bg-white;

		.menu-item {
			a {
				@apply text-group;

				@screen md {
					&::after {
						@apply bg-group;
					}
				}
			}
		}

		.logo {
			svg path {
				fill: theme("colors.group");
			}
		}

		.navicon {
			@apply bg-group;

			&::before,
			&::after {
				@apply bg-group;
				content: "";
			}
		}
	}

	&.transparent-header {
		@apply bg-transparent;

		.menu-item {
			a {
				@apply text-white;

				@screen md {
					&::after {
						@apply bg-white;
					}
				}
			}
		}

		.logo {
			svg path {
				fill: theme("colors.white");
			}
		}

		&.white {
			@apply bg-white;

			.menu-item {
				a {
					@apply text-group;

					@screen md {
						&::after {
							@apply bg-group;
						}
					}
				}
			}
		
			.logo {
				svg path {
					fill: theme("colors.group");
				}
			}

			.contact-button-light {
				@apply hidden;
			}

			.contact-button-dark {
				@apply flex;
			}

			.menu-icon {
				.navicon {
					@apply bg-group;
		
					&::before,
					&::after {
						@apply bg-group;
					}
				}
			}

			.lang-switcher {
				@apply text-group;
			}
		}
	}

	.lang-switcher {
		@apply text-white;
		@apply font-basiersquare-semibold;
		@apply uppercase;
		@apply text-menu;
	}
}
