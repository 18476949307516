.general-intro {
	.image-container {
		@apply overflow-hidden;
		
		picture {
			transition: transform 0.3s ease;
		}

		&:hover {
			picture {
				transform: scale(1.05);
			}
		}
	}

	.iframe-container {
		@apply relative;
		@apply overflow-hidden;
		padding-bottom: 56.25%;
	}

	.iframe-container iframe,
	.iframe-container object,
	.iframe-container embed {
		@apply absolute;
		@apply w-full;
		@apply h-full;
		top: 0;
		left: 0;
	}
}
