.faq-page {
	.add-icon {
		transition: all 0.5s ease-in-out;

		&.rotated {
			transform: rotate(45deg);
		}
	}

	.svg-div {
		svg {
			width: 30px;
			height: 30px;

			@screen md {
				width: 40px;
				height: 40px;
			}
		}
	}

	.answer-faq {
		transition: all 0.25s ease-in-out;
	}

	.answer-redactor-field {
		p {
			// @apply text-primary;
			// @apply text-body-regular-mobile;
			// @apply font-futura-regular;
			@apply pb-4;

			@screen md {
				@apply pb-8;
				// @apply text-body-regular;
			}
		}

		ul {
			li {
				// @apply text-primary;
				// @apply text-body-regular-mobile;
				// @apply font-futura-regular;
				@apply mb-3;
				@apply flex;

				@screen md {
					@apply mb-4;
				}

				&::before {
					content: "";
					// background-image: url("../../images/ic_bullet.svg");
					background-repeat: no-repeat;
					height: 20px;
					width: 48px;
					@apply block;
					@apply mt-1;
					@apply mr-4;
					@apply pr-1.5;

					@screen md {
						width: 32px;
					}

					@screen lg {
						width: 25px;
					}
				}
			}
		}
	}
}
