.case-study-slider {
	.headline {
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: theme("colors.black");
	}

	.case-study-filter {
		button {
			@apply text-black;
			@apply border;
			@apply cursor-pointer;
			@apply text-base-mobile;
			@apply md:text-base;
			@apply font-basiersquare-regular;
			@apply py-2;
			@apply px-5;
			@apply md:px-6;
			@apply rounded-25;
			transition: background-color 0.3s ease;

			&.all {
				@apply border-greylight;

				&:hover {
					@apply bg-greylight;
				}

				&.active {
					@apply bg-greylight;
				}
			}

			&.stargate-creative {
				@apply border-creative;

				&:hover {
					@apply bg-creative;
					@apply text-white;
				}

				&.active {
					@apply bg-creative;
					@apply text-white;
				}
			}
	
			&.stargate-live {
				@apply border-live;

				&:hover {
					@apply bg-live;
					@apply text-white;
				}

				&.active {
					@apply bg-live;
					@apply text-white;
				}
			}
	
			&.stargate-group {
				@apply border-group;
				
				&:hover {
					@apply bg-group;
					@apply text-white;
				}

				&.active {
					@apply bg-group;
					@apply text-white;
				}
			}
		}
	}

	.case-study-card {
		.stargate-creative {
			@apply bg-creative;
		}

		.stargate-live {
			@apply bg-live;	
		}

		.stargate-group {
			@apply bg-group;	
		}

		.bullet {
			@apply bg-white;
			@apply inline-block;
			@apply w-1;
			@apply h-1;
			@apply rounded-full;
		}
	}

	.image-container {
		@apply overflow-hidden;
		
		picture {
			transition: transform 0.3s ease;
		}

		@screen md {
			&:hover {
				picture {
					transform: scale(1.05);
				}
			}
		}
	}

	.case-study-card-text {
		/* stylelint-disable */
		display: -webkit-box;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;
		/* stylelint-enable */

		@screen sm {
			/* stylelint-disable */
			-webkit-line-clamp: 4;
			/* stylelint-enable */
		}

		@screen lg {
			/* stylelint-disable */
			-webkit-line-clamp: 5;
			/* stylelint-enable */
		}
	}

	.background-linear {
		background: linear-gradient(270.32deg, #fcfcfc 0.28%, rgba(252, 252, 252, 0) 99.72%);
	}
}
