.button {
	@apply cursor-pointer;
	@apply no-underline;

	&.primary {
		@apply bg-group;
		@apply h-12;
		@apply md:h-14;
		@apply text-white;
		@apply text-button-mobile;
		@apply md:text-button;
		@apply font-basiersquare-regular;
		@apply min-w-button-mobile;
		@apply md:min-w-button;
		transition: background-color 0.3s ease;

		&:hover {
			@apply bg-groupShade;
		}
	}

	&.outlined-white {
		@apply h-12;
		@apply md:h-14;
		@apply text-white;
		@apply text-button-mobile;
		@apply md:text-button;
		@apply font-basiersquare-regular;
		@apply border;
		@apply border-white;
	}

	&.secondary {
		@apply h-12;
		@apply md:h-14;
		@apply text-white;
		@apply text-button-mobile;
		@apply md:text-button;
		@apply font-basiersquare-regular;
		@apply border-2;
		@apply border-white;
		@apply min-w-full;
		@apply md:min-w-button;
		transition: background-color 0.3s ease;

		&:hover {
			@apply bg-white;
		}
	}

	&.arrow {
		@apply text-white;
		@apply uppercase;
		@apply text-button-submit-mobile;
		@apply md:text-button-submit;
		@apply font-basiersquare-semibold;
		@apply flex;
		@apply my-auto;

		&::after {
			content: "";
			background-image: url("../../images/flatbutton-arrow-white.png");
			@apply bg-contain;
			@apply w-5;
			@apply h-3;
			@apply block;
			@apply ml-2;
			@apply flex;
			@apply my-auto;
			@apply bg-no-repeat;
		}

		&:hover {
			@apply bg-transparent;

			&::after {
				content: "";
				background-image: url("../../images/flatbutton-arrow-hover.png");
				@apply bg-contain;
				@apply w-10;
				@apply h-3;
				@apply block;
				@apply ml-2;
				@apply flex;
				@apply my-auto;
				@apply bg-no-repeat;
			}
		}
	}

	&.arrow-group {
		@apply text-group;
		@apply uppercase;
		@apply text-button-flat;
		@apply font-basiersquare-semibold;
		@apply flex;
		@apply my-auto;

		&::after {
			content: "";
			background-image: url("../../images/flatbutton-arrow-blue.png");
			@apply bg-contain;
			@apply w-5;
			@apply h-3;
			@apply block;
			@apply ml-2;
			@apply flex;
			@apply my-auto;
			@apply bg-no-repeat;
		}

		// &:hover {
		// 	@apply bg-transparent;

		// 	&::after {
		// 		content: "";
		// 		background-image: url("../../images/flatbutton-arrow-hover-blue.png");
		// 		@apply bg-contain;
		// 		@apply w-10;
		// 		@apply h-3;
		// 		@apply block;
		// 		@apply ml-2;
		// 		@apply flex;
		// 		@apply my-auto;
		// 		@apply bg-no-repeat;
		// 		@apply overflow-hidden;
		// 	}
		// }
	}

	&.live {
		&:hover {
			@apply text-live;
		}
	}

	&.creative {
		&:hover {
			@apply text-creative;
		}
	}

	&.group {
		&:hover {
			@apply text-group;
		}
	}

	&.stargate-live {
		&:hover {
			@apply text-live;
		}
	}

	&.stargate-creative {
		&:hover {
			@apply text-creative;
		}
	}

	&.stargate-group {
		&:hover {
			@apply text-group;
		}
	}
}
