* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	scroll-behavior: smooth;
}

body {
	min-height: 100%;
	@apply bg-background;
}

.content {
	min-height: 100vh;
}

// global swiper flickering fix
.swiper-slide {
	/* stylelint-disable */
	-webkit-transform: translate3d(0, 0, 0);
	/* stylelint-enable */
}

// Image Lazy Load Blurred Preview
img.ls-blur-up-img {
	display: none !important;
}

img.lazyloading.blur-lazy,
img.lazyload.blur-lazy {
	filter: blur(15px);
	transform: translateZ(0);
	transition: filter 0.35s ease-out;
	overflow: hidden;
}

img.lazyloaded.blur-lazy {
	transition: filter 0.35s ease-out;
	transform: translateZ(0);
	filter: blur(0);
}

img.lazyload:not([src]) {
	visibility: hidden;
}

// Set max width depending on design
.container {
	max-width: 1224px;
	@apply mx-auto;
}

.row {
	@apply -mx-4;
	@apply md:-mx-3;
	@apply flex;
	@apply flex-wrap;
	@apply w-full;
	@apply mx-auto;
}

.col {
	@apply px-4;
	@apply md:px-3;
}

.image-container {
	@apply overflow-hidden;

	&.image-scale {
		picture {
			transition: transform 0.3s ease;
		}
	}

	&.image-scale {
		&:hover {
			picture {
				transform: scale(1.05);
			}
		}
	}
}


.body-bg-dark {
	@apply bg-black;
	@apply bg-opacity-10;
}

.redactor {
	&.text-only {
		@apply font-basiersquare-semibold;
		@apply text-h2-mobile;
		@apply md:text-h2;
		@apply text-black;
	}

	&.text-only-h3-style {
		@apply font-basiersquare-semibold;
		@apply text-h3-mobile;
		@apply md:text-h3;
		@apply text-black;
	}

	&.bold-only {
		@apply text-base text-black underline font-basiersquare-regular;

		strong {
			@apply font-basiersquare-semibold;
			@apply font-normal;
		}
	}

	&.legal-page {
		@apply font-basiersquare-regular;
		@apply text-base-mobile;
		@apply md:text-base;
		@apply text-black;

		strong {
			@apply font-basiersquare-semibold;
			@apply font-normal;
		}

		h2 {
			@apply font-basiersquare-semibold;
			@apply text-h3-mobile;
			@apply md:text-h3;
			@apply mb-4;
		}

		h3 {
			@apply font-basiersquare-semibold;
			@apply text-h5-mobile;
			@apply md:text-h5;
			@apply mb-2;
		}

		p {
			@apply mb-2;
		}

		ul,
		ol {
			@apply mb-2;
			@apply pl-5;
		}

		ol {
			list-style-type: decimal;
		}

		ul {
			list-style-type: disc;
		}

		a {
			@apply font-basiersquare-semibold;
			@apply text-group;

			&:hover {
				@apply underline;
			}
		}
	}

	&.footer {
		@apply font-basiersquare-regular;
		@apply text-base-mobile;
		@apply md:text-base;
		@apply text-white;

		a {
			&:hover {
				@apply underline;
			}
		}

		a[title="icon"] {
			@apply flex;
			@apply items-center;

			&::after {
				content: "";
				background-image: url("../../images/ic_arrow_forward.png");
				@apply bg-contain;
				@apply w-4;
				@apply h-4;
				@apply block;
				@apply ml-2;
			}
		}
	}
}

// for media-queries use:
// 		@screen md {
// 			...
// 		}
