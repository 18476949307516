.cookie-banner-tab {
	@apply flex;
	@apply fixed;
	@apply cursor-pointer;
	@apply rounded-t-xl;
	@apply left-2;
	@apply bg-white;
	bottom: -30px;
	transition: bottom 0.3s ease;
	border: 1px solid theme("colors.greylight");

	&:hover {
		@apply bottom-0;
	}

	a {
		@apply px-5;
		@apply py-2;
		@apply text-black;
		@apply text-base-mobile;
		@apply md:text-base;
		@apply font-basiersquare-regular;
	}
}

#cookie-banner {
	.cookie-notice {
		@apply w-full;
		@apply max-w-full;
		@apply left-0;
		@apply right-0;
		@apply bottom-0;
		@apply rounded-none;
		@apply bg-white;

		.cn-body {
			@apply flex;
			@apply p-6;
			@apply md:px-10;
			@apply md:py-6;
			@apply w-full;
			@apply flex-col;
			@apply font-basiersquare-regular;
			@apply text-base;

			@screen xl {
				@apply flex-row;
				@apply items-center;
				@apply justify-between;
			}

			p {
				@apply text-base;
				@apply w-full;
				@apply max-w-full;
				@apply xl:w-2/5;
				@apply 2xl:w-1/2;
				@apply 3xl:w-3/5;

				span {
					@apply max-w-full;
					@apply text-black;
				}

				a {
					@apply text-black;
					@apply underline;
				}
			}

			.cn-ok {
				@apply mt-2;

				@screen xl {
					@apply mt-0;
				}
			}

			.cn-learn-more {
				@apply text-base;
				@apply text-group;
				@apply font-basiersquare-regular;
				@apply text-button-mobile;
				@apply md:text-button;
				@apply mt-0;
				@apply mr-4;
				@apply mb-4;
				@apply md:mb-0;

				&:hover {
					@apply underline;
				}
			}
		}
	}

	.cm-modal {
		@apply bg-white;
		@apply rounded-none;

		.title {
			@apply text-black;
			@apply font-basiersquare-semibold;
			@apply text-h4-mobile;
			@apply md:text-h4;
		}

		p {
			@apply my-3;
		}

		p,
		label span {
			@apply text-base-mobile;
			@apply md:text-base;
			@apply text-black;
		}

		a {
			@apply text-group;
			@apply underline;
		}

		.cm-header,
		.cm-footer {
			@apply border-greylight;
			@apply p-6;
		}

		.cm-header {
			button {
				svg {
					stroke: #4f4f4f;
				}
			}
		}

		.cm-body {
			@apply px-6;
			@apply py-8;
		}

		.cm-list-label .slider { 
			@apply h-6;
			@apply w-12;
			box-shadow: none;

			&::before {
				@apply w-5;
				@apply h-5;
			}
		}

		.cm-list-input:checked + .cm-list-label .slider {
			@apply bg-group;

			&::before {
				@apply bg-white;
				left: 6px;
				bottom: 2px;
			}
		}

		.cm-list-input + .cm-list-label .slider {
			@apply bg-greylight;

			&::before {
				@apply bg-white;
				left: 2px;
				bottom: 2px;
			}
		}

		.cm-required {
			@apply text-grey;
			@apply font-basiersquare-regular;
			@apply text-base-mobile;
			@apply md:text-base;
		}

		.cm-list-title {
			@apply font-basiersquare-semibold;
			@apply font-normal;
			@apply text-base-mobile;
			@apply md:text-base;
		}

		.cm-services {
			.cm-caret {
				a {
					@apply font-basiersquare-regular;
					@apply text-group;
					@apply no-underline;
					@apply text-base-small-mobile;
					@apply md:text-base-small;
				}
			}
		}

		.cm-purpose {
			@apply mb-6;

			&:last-child {
				@apply mb-0;
			}

			.purposes {
				@apply m-0;
				@apply font-basiersquare-regular;
				@apply text-base-small-mobile;
				@apply md:text-base-small;
				@apply text-grey;
			}
		}

		.cm-footer {
			.cm-footer-buttons {
				@apply flex;
				@apply justify-center;
			}

			button {
				@apply flex;
				@apply justify-center;
				@apply items-center;
			}

			.cm-btn-decline {
				@apply order-2;
				@apply mr-0;
				@apply mx-4;
			}

			.cm-btn-accept {
				@apply order-1;
				@apply text-group;
				@apply text-button-mobile;
				@apply md:text-button;
				@apply font-basiersquare-regular;
			}

			.cm-btn-accept-all {
				@apply order-3;
			}
		}
	}

	.cn-buttons {
		@apply m-0;
		@apply flex;
		@apply w-full;
		@apply justify-between;
		@apply sm:justify-start;

		@screen md {
			@apply w-auto;
			@apply justify-end;
		}
	}

	.cm-btn-success {
		@apply w-60;
		@apply mr-0;
		@apply mt-0;
		@apply bg-group;
		@apply text-white;
		@apply text-button-mobile;
		@apply md:text-button;
		@apply h-14;
		@apply md:h-18;
		@apply rounded-none;
		@apply font-basiersquare-regular;
		transition: background-color 0.3s ease;

		&:hover {
			@apply bg-groupShade;
		}
	}

	.cn-decline {
		transition: border-color 0.3s ease, color 0.3s ease;
		@apply text-button-mobile;
		@apply md:text-button;
		@apply bg-transparent;
		@apply w-60;
		@apply text-center;
		@apply mr-4;
		@apply mt-0;
		@apply text-group;
		@apply font-basiersquare-regular;
		@apply border-2;
		@apply border-group;
		@apply h-14;
		@apply md:h-18;
		@apply rounded-none;
		@apply border-solid;

		&:hover {
			@apply border-groupShade;
			@apply text-groupShade;
		}
	}

	.cm-btn-accept {
		@apply text-base-mobile;
		@apply md:text-base;
		@apply normal-case;
		@apply mt-0;
		@apply font-basiersquare-regular;
		background: transparent;

		&:hover {
			background: transparent;
			@apply underline;
		}
	}

	.cm-powered-by {
		@apply hidden;
	}
}
