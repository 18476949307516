
@font-face {
	font-family: "basiersquare-regular";
	font-display: swap;
	src: url("/src/fonts/basiersquare-regular-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "basiersquare-semibold";
	font-display: swap;
	src: url("/src/fonts/basiersquare-semibold-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}



