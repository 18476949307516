.text-tiles {
	.tile-bg,
	.back {
		&.stargate-creative {
			@apply bg-creative;
		}

		&.stargate-live {
			@apply bg-live;	
		}

		&.stargate-group {
			@apply bg-group;	
		}
	}

	.tile {
		.back {
			@apply opacity-0;
			transition: opacity 0.3s ease;

			.hover-headline {
				-webkit-text-stroke-width: 1px;
				-webkit-text-stroke-color: theme("colors.white");
			}
		}

		&:hover,
		&.on-hover {
			.back {
				@apply opacity-100;
			}
		}
	}
}
