.appliction-modal {
	@apply hidden;
	@apply fixed;
	@apply z-40;
	@apply left-0;
	@apply top-40;
	@apply w-full;
	@apply h-full;
	@apply overflow-auto;
	@apply bg-transparent;
	@apply px-4;
	@apply md:px-3;

	.appliction-modal-content {
		max-width: 1016px;
		@apply mx-auto;
		@apply flex;
		@apply mx-auto;
		@apply bg-white;
		@apply w-full;
	}
}

.application-form {
	// Form
	.fui-page-container {
		@apply w-full;

		.fui-page-row:first-child {
			@apply w-full;
			@apply h-10;
			@apply mb-4;

			@screen md {
				width: 70%;
				@apply mb-5;
			}
		}
	}

	.fui-form {
		@apply font-basiersquare-regular;
	}

	.fui-field-container {
		@apply w-full;

		@screen md {
			margin-left: -0.5rem;
			margin-right: -0.5rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}

	.fui-row {
		@apply mx-0;
		@apply flex-col;
		@apply lg:flex-row;
	}

	.fui-form-container {
		> div {
			@apply flex;
			@apply relative;
			@apply flex-col;

			// @screen md {
			// 	@apply flex-row;
			// }
		}
	}

	// Mail
	.fui-type-email-address {
		@apply flex;
		@apply items-center;
		@apply flex-col;
		@apply mb-6;
		@apply lg:mb-8;
		@apply px-0;

		.fui-field-container {
			@apply w-full;
			@apply flex;
			@apply justify-center;
			@apply relative;
			@apply flex-col;
			@apply px-0;
		}

		.fui-input-container {
			@apply w-full;
		}

		input {
			@apply border-0;
			@apply border-b;
			@apply rounded-none;
			@apply bg-transparent;
			@apply text-base;
			@apply text-black;
			@apply focus:outline-none;
			@apply focus:shadow-none;
			transition: border 0.2s;
			@apply px-0;
			@apply py-2;
			@apply w-full;
		}

		&.fui-error {
			label {
				@apply text-black;
			}

			.fui-error-message {
				@apply text-error;
				@apply mt-1;
				@apply mb-1;
				@apply text-form-float-small;
			}

			input {
				@apply text-black;
				@apply border-error;
			}
		}
	}

	.fui-label {
		@apply text-black;
		@apply text-base;
		@apply top-0;
		@apply left-0;
		@apply m-0;
		@apply mt-2;
		@apply absolute;
		transition: top 0.2s, bottom 0.2s, right 0.2s, left 0.2s;

		&.float {
			@apply text-form-float-small;
			@apply text-black;
			@apply bg-white;
			@apply -top-7;
		}

		&.selected {
			@apply text-black;
		}
	}

	.fui-required {
		@apply hidden;
	}

	// Checkbox Container
	.fui-type-agree {
		@apply flex;
		@apply flex-col;
		@apply px-0;

		@screen md {
			@apply px-2;
		}

		.fui-field-container {
			@apply flex;
			@apply flex-col;
			@apply relative;
			@apply p-0;
		}

		.fui-legend {
			@apply text-base;
			@apply text-black;
			@apply mb-3;
		}

		.fui-checkbox {
			@apply text-base;
			@apply text-black;

			label {
				@apply text-black;
				@apply text-caption;
				@apply pl-7;
				@apply block;
				@apply items-center;
				@apply h-5;
				@apply relative;

				@screen md {
					@apply pl-0;
					@apply flex;
				}

				a {
					@apply underline;

					@screen md {
						@apply ml-1.5;
					}
				}
			}

			input {
				@apply w-0;
				@apply h-0;
				@apply invisible;
			}

			input + label::before {
				content: "";
				background-image: url("../../images/checkbox-blau-nonactive.svg");
				@apply absolute;
				@apply top-0;
				@apply left-0;
				@apply bg-transparent;
				@apply bg-auto;
				@apply bg-center;
				@apply border-transparent;
				@apply border;
				@apply w-5;
				@apply h-5;
				@apply rounded-none;
				@apply mr-2.5;
				@apply bg-no-repeat;

				@screen md {
					@apply relative;
				}
			}

			input:checked + label::before {
				background-image: url("../../images/checkbox-blau-active.svg");
				@apply bg-no-repeat;
			}
		}

		.fui-checkbox:not(:last-child) {
			@apply mb-3;
		}

		.fui-fieldset {
			@apply px-4;
			@apply lg:px-0;
		}

		.fui-error-message {
			@apply text-error;
			@apply w-full;
			@apply mt-4;
			@apply text-form-float-small;

			@screen md {
				@apply -mx-2;
				@apply mt-1;
			}
		}

		&.fui-error {
			.fui-checkbox {
				input + label::before {
					@apply border-error;
				}
			}
		}
	}

	// Submit Button
	.fui-btn-container {
		@apply w-full;
		@apply right-0;
		@apply pt-0;
		@apply h-10;
		@apply mt-6;
		@apply flex;

		@screen md {
			width: 30%;
			// @apply absolute;
			@apply whitespace-nowrap;
			@apply mt-0;
		}
	}

	.fui-submit {
		@apply font-basiersquare-semibold;
		@apply bg-transparent;
		@apply text-group;
		@apply flex;
		@apply flex-row;
		@apply focus:outline-none;
		@apply border-0;
		@apply p-0;
		@apply text-button-submit-mobile;
		@apply uppercase;
		@apply my-auto;

		@screen md {
			@apply text-button-submit;
			@apply items-end;
			@apply mx-2;
		}

		&::after {
			content: "";
			background-image: url("../../images/flatbutton-arrow-blue.png");
			@apply bg-contain;
			@apply w-5;
			@apply h-3;
			@apply flex;
			@apply block;
			@apply ml-2;
			@apply my-auto;
			@apply bg-no-repeat;
		}

		&:hover {
			@apply bg-transparent;
			@apply text-group;

			&::after {
				content: "";
				background-image: url("../../images/flatbutton-arrow-hover-blue.png");
				@apply bg-contain;
				@apply w-10;
				@apply h-3;
				@apply block;
				@apply ml-2;
				@apply flex;
				@apply my-auto;
				@apply bg-no-repeat;
			}
		}

		&.fui-loading {
			@apply text-black;

			&::after {
				background-image: none;
			}
		}
	}

	.fui-input.fui-error:focus {
		box-shadow: none;
	}

	// General error message
	.fui-alert-error {
		@apply hidden;
	}

	.fui-alert-success {
		@apply w-auto;
		@apply left-0;
		@apply text-black;
		@apply p-0;
		@apply rounded-none;
		@apply bg-transparent;
		@apply font-basiersquare-regular;
		@apply text-caption;
		@apply mt-4;
		@apply absolute;
		@apply top-36;
		
		@screen md {
			@apply top-32;
		}

		&::before {
			content: "";
			background-image: url("../../images/ic_check-active-group.png");
			@apply bg-contain;
			@apply flex;
			@apply inline-block;
			@apply w-4;
			@apply h-2.5;
			@apply my-auto;
			@apply bg-no-repeat;
		}
	}
}



